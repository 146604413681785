import * as PropTypes from "prop-types"
import * as React from "react"
import { Arrow } from "./arrow"
import style from "./city-page-content.module.scss"
import WideImage from "./images/wide-image"
import { SpotDetail } from "./spot-detail"
import { spotPropTypes } from "./spot-prop-types"

const imageStyles = {
  position: "absolute",
  height: "100%",
  width: "100%",
}

export class CitySpot extends React.Component {
  constructor(props) {
    super(props)
    this.state = { detailOpen: false }
  }

  render() {
    const { photo, businessName, description, topNew } = this.props.spot
    const shortDescription =
      (description || "").length >= 100
        ? description.slice(0, 100) + "..."
        : description
    return (
      <li
        className={style.spotItem}
        onClick={this.handleOpen}
        onKeyUp={this.handleOpen}
      >
        {this.state.detailOpen && (
          <SpotDetail spot={this.props.spot} onDismiss={this.handleDismiss} />
        )}
        <WideImage filename={photo} style={imageStyles} />
        <div className={style.spotBackdrop} />
        {topNew === 1 &&
        <div className={style.topNew}>Top New</div>}
        <div className={style.spotContent}>
          <h1 className={style.spotName}>{businessName}</h1>
          <p className={style.spotDescription}>{shortDescription}</p>
          <p className={style.spotLink}>
            Read More{" "}
            <Arrow className={style.spotArrow} height={15} width={15} />
          </p>
        </div>
      </li>
    )
  }

  handleOpen = event => {
    event.stopPropagation()
    this.setState({ detailOpen: true })
  }
  handleDismiss = event => {
    event.stopPropagation()
    this.setState(() => {
      return {
        detailOpen: false,
      }
    })
  }
}

CitySpot.propTypes = {
  spot: spotPropTypes.isRequired,
  lastUpdatedAt: PropTypes.any,
  onClick: PropTypes.any,
}

CitySpot.defaultProps = {
  description: "",
}
