import * as PropTypes from "prop-types"
import * as React from "react"
import * as ReactDOM from "react-dom"
import { Arrow } from "./arrow"
import { ExternalLink } from "./external-link"
import style from "./spot-detail.module.scss"
import WideImage from "./images/wide-image"
import { spotPropTypes } from "./spot-prop-types"

const imageStyles = {
  position: "absolute",
  height: "100%",
  width: "100%",
  top: "0",
  left: "0",
  zIndex: "950",
}

const CloseIcon = props => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.001 512.001"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <g>
        <path
          d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717
			L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859
			c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287
			l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285
			L284.286,256.002z"
        />
      </g>
    </g>
  </svg>
)

const arrow = () => <Arrow color={null} className={style.arrowStyle} />

class CityPageDisplay extends React.Component {
  render() {
    const { onDismiss, spot } = this.props
    const { businessName, website, description, personalNote, photo } = spot
    return (
      <div
        className={style.modalOverlay}
        onClick={onDismiss}
        onKeyUp={onDismiss}
        role="dialog"
        aria-modal
      >
        <div className={style.modalWrapper}>
          <button
            className={style.modalDismiss}
            onClick={onDismiss}
            onKeyUp={onDismiss}
          >
            <CloseIcon height={20} width={20} className={style.dismissIcon} />
          </button>
          <article
            className={style.modalContainer}
            onClick={this.swallowEvent}
            onKeyUp={this.swallowEvent}
          >
            <WideImage filename={photo} style={imageStyles} />
            <div className={style.modalContent}>
              <h1 className={style.modalTitle}>{businessName}</h1>
              <div className={style.modalDetail}>
                <div className={style.modalUpdates}>
                  <h2 className={style.itemDescription}>{description}</h2>
                  <p className={style.itemUpdateDetail}>{personalNote}</p>
                </div>
                <div className={style.modalInfo}>
                  <div>
                    <h2 className={style.itemTitle}>
                      <ExternalLink href={website}>
                        Website {arrow()}
                      </ExternalLink>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    )
  }

  swallowEvent = event => {
    event.stopPropagation()
  }
}

CityPageDisplay.propTypes = {
  spot: spotPropTypes,
  onDismiss: PropTypes.any,
}

const SpotDetail = props => {
  return ReactDOM.createPortal(<CityPageDisplay {...props} />, document.body)
}

export { SpotDetail }
