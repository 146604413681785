import * as React from "react"
import * as PropTypes from "prop-types"
import style from "./location-tabs.module.scss"

const LocationTab = ({ label, value, isActive, onChangeCategory }) => {
  const handler = () => onChangeCategory(value)
  return (
    <li
      className={isActive ? style.spotTabsActive : style.spotTabsInactive}
      onClick={handler}
      onKeyUp={handler}
      role="link"
    >
      {label}
    </li>
  )
}

export const LocationTabs = ({
  currentCategory,
  categories,
  onChangeCategory,
}) => (
  <ul className={style.spotTabs}>
    {categories.map(category => (
      <LocationTab
        key={category.value}
        label={category.label}
        value={category.value}
        isActive={category.value === currentCategory}
        onChangeCategory={onChangeCategory}
      />
    ))}
  </ul>
)

LocationTabs.propTypes = {
  currentCategory: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChangeCategory: PropTypes.func.isRequired,
}
