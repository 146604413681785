import * as React from "react"
import * as moment from "moment-timezone"
import { graphql } from "gatsby"
import { CityPageContent } from "../components/city-page-content"
import Layout from "../components/layout"

const CityPage = ({ data, pageContext }) => {
  const city = pageContext.node
  const filteredSpots = data.spots.nodes
    .map(spot => ({
      ...spot,
      momentDate: moment.tz(spot.lastUpdatedAt, "America/Los_Angeles"),
      topNew: (spot.topNew || "").toLowerCase() === "y" ? 1 : 0,
      category:
        city.locationType === "city" ? spot.cityCategory : spot.virtualCategory,
    }))
    .sort((a, b) => {
      if (a.topNew !== b.topNew) {
        return b.topNew - a.topNew
      }
      return b.momentDate.unix() - a.momentDate.unix()
    })
  return (
    <Layout
      backgroundImage={city.image}
      seoImage={data.cityImage}
      title={`Keep Local Alive - ${city.name}`}
    >
      <CityPageContent
        type={city.locationType}
        city={city.name}
        state={city.state}
        sponsor={city.sponsor}
        sponsorLink={city.sponsorLink}
        spots={filteredSpots}
      />
    </Layout>
  )
}

export const query = graphql`
  query MyQuery($cityDataValue: String!, $imageFilename: String!) {
    cityImage: file(relativePath: { eq: $imageFilename }) {
      name
      id
      childImageSharp {
        fluid {
          src
        }
      }
    }
    spots: allGoogleSheetFormResponses1Row(
      filter: {
        verificationStatus: { eq: "Y" }
        photo: { ne: null }
        city: { eq: $cityDataValue }
      }
    ) {
      nodes {
        businessName: businessname
        cityCategory: category
        virtualCategory: type
        locationType: location
        city
        id
        topNew
        description: oneliner
        personalNote: description
        lastUpdatedAt: timestamp
        website
        photo
      }
    }
  }
`

export default CityPage
