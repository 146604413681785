import * as PropTypes from "prop-types"
import * as React from "react"
import style from "./city-page-content.module.scss"
import { CitySpot } from "./city-spot"
import { ExternalLink } from "./external-link"
import { LocationTabs } from "./location-tabs"
import { spotPropTypes } from "./spot-prop-types"

const itemsPerPage = 6

const cityCategories = [
  { value: "eat", label: "Eat" },
  { value: "play", label: "Play" },
]
const onlineCategories = [
  { value: "fun", label: "Fun" },
  { value: "exercise", label: "Exercise" },
  { value: "learn", label: "Learn" },
]

function filterSpots(spots, categoryPrefix) {
  return spots.filter(spot => {
    if (typeof spot.category !== "string") {
      return false
    }
    return (
      spot.category.substring(0, 1).toLowerCase() ===
      categoryPrefix.substring(0, 1).toLowerCase()
    )
  })
}

class CityPageContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      detailOpen: false,
      currentCategory: (props.type === "city"
        ? cityCategories
        : onlineCategories)[0].value,
      limit: itemsPerPage,
    }
  }

  renderSponsor(sponsor, sponsorLink, city) {
    return (
      <h2 className={style.headerAccentText}>
        Sponsored by{" "}
        {sponsorLink ? (
          <ExternalLink href={sponsorLink} className={style.headerLink}>
            {sponsor}
          </ExternalLink>
        ) : (
          sponsor
        )}
        , a proud supporter of the {city} Community
      </h2>
    )
  }

  renderCityHeader(city) {
    return (
      <p className={style.headerDetail}>
        Here are updates of all of the {city} restaurants, stores, and services
        that are still open for business! Click on any spot to learn about
        specials, hours, delivery options, ways to donate and more.
      </p>
    )
  }

  renderOnlineHeader() {
    return (
      <p className={style.headerDetail}>
        Here are virtual opportunities offered by local businesses that can be
        accessed no matter where you are! Start by choosing a category below.
      </p>
    )
  }

  render() {
    let { city, state, sponsor, spots, sponsorLink, type } = this.props
    const citySpots = filterSpots(spots, this.state.currentCategory)
    const limitedSpots = citySpots.slice(0, this.state.limit)
    const spotElements = limitedSpots.map(spot => (
      <CitySpot spot={spot} key={spot.id} />
    ))
    const header =
      type === "city" ? (
        <h1 className={style.headerText}>
          {city}, {state}
        </h1>
      ) : (
        <h1 className={style.headerText}>{city}</h1>
      )

    const categories = type === "city" ? cityCategories : onlineCategories
    return (
      <>
        <section className={style.header}>
          {header}
          {sponsor && this.renderSponsor(sponsor, sponsorLink, city)}
          {type === "city" && this.renderCityHeader()}
          {type === "virtual" && this.renderOnlineHeader()}
        </section>
        <section className={style.citySpots}>
          <LocationTabs
            currentCategory={this.state.currentCategory}
            onChangeCategory={this.handleChangeCategory}
            categories={categories}
          />
          <ul className={style.spotList}>{spotElements}</ul>
          {citySpots.length > this.state.limit && (
            <div className={style.spotMoreContainer}>
              <button
                className={style.spotMoreButton}
                onClick={this.handleShowMore}
              >
                Show More
              </button>
            </div>
          )}
        </section>
      </>
    )
  }

  handleShowMore = () => {
    this.setState({ limit: this.state.limit + itemsPerPage })
  }

  handleChangeCategory = category => {
    this.setState({ currentCategory: category, limit: itemsPerPage })
  }
}

CityPageContent.propTypes = {
  city: PropTypes.string.isRequired,
  state: PropTypes.string,
  sponsor: PropTypes.string,
  spots: PropTypes.arrayOf(spotPropTypes),
  type: PropTypes.oneOf(["city", "country", "virtual"]).isRequired,
}

export { CityPageContent }
