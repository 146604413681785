import * as PropTypes from "prop-types"

export const spotPropTypes = PropTypes.exact({
  photo: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  cityCategory: PropTypes.string,
  virtualCategory: PropTypes.string,
  businessName: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  locationType: PropTypes.string.isRequired,
  personalNote: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  website: PropTypes.string,
  lastUpdatedAt: PropTypes.string,
  momentDate: PropTypes.object.isRequired,
  topNew: PropTypes.number,
})
